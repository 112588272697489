import { useEffect, useState } from 'react';
import axios from 'axios';

import { NavbarV2 } from '../navbar/NavBar';
import './main.css'
import { ArticleLayout, Padding } from '../compLib';


const Articles = () => {

    // // Sdk implementation
    // var Airtable = require('airtable');
    // var base = new Airtable({ apiKey: process.env.REACT_APP_AIRTABLE_ARTICLES_API_KEY }).base(process.env.REACT_APP_AIRTABLE_ARTICLES_BASE_ID);

    // base('Articles').select({
    //     // maxRecords: 3,
    //     view: "Grid view"
    // }).eachPage(function page(records: any[], fetchNextPage: () => void) {
    //     // This function (`page`) will get called for each page of records.

    //     records.forEach(function (record, i) {
    //         articleList[i] = record.fields
    //     });

    //     // To fetch the next page of records, call `fetchNextPage`.
    //     // If there are more records, `page` will get called again.
    //     // If there are no more records, `done` will get called.
    //     fetchNextPage();

    // }, function done(err: any) {
    //     if (err) { console.error(err); return; }
    // });

    // // Axios implementation
    // axios.get(`https://api.airtable.com/v0/${process.env.REACT_APP_AIRTABLE_ARTICLES_BASE_ID}/${process.env.REACT_APP_AIRTABLE_ARTICLES_TABLE_NAME}`, {
    //     headers: {
    //         'Authorization': `Bearer ${process.env.REACT_APP_AIRTABLE_ARTICLES_API_KEY}`,
    //         'Content-Type': 'application/json'
    //     }
    // })
    //     .then((response) => {
    //         // handle the response from Airtable
    //         console.log("AXIOS", response);
    //         articleList = mapAPIResponseToBlogArticles(response.data.fields)
    //     })
    //     .catch((error) => {
    //         // handle any errors
    //         console.error(error);
    //     });

    return (
        <>
            <NavbarV2
                logoRef='/'
                brand="Benevosa"
                navItems={[
                    { label: 'Home', href: '/' },
                    { label: 'About', href: '/about' },
                    // { label: 'Articles', href: '/articles' },
                    // { label: 'Team', href: '/team' },
                    { label: '#Challenge', href: '/environment-challenge' },
                    { label: 'Contact', href: '/contact' },
                    { label: 'Newsletter', href: '/newsletter' },
                ]} />
                <Padding padding='2rem' />
            <h1>
                Articles
            </h1>

            <Example />

        </>
    );
};

export default Articles;


const Example = () => {
    // define the state variable and setState function using the useState hook
    const [data, setData] = useState<any | null>(null);

    // use the useEffect hook to make the API request when the component is mounted
    useEffect(() => {
        const fetchData = async () => {
            // make the API request using the axios library
            const response = await axios.get(`https://api.airtable.com/v0/${process.env.REACT_APP_AIRTABLE_ARTICLES_BASE_ID}/${process.env.REACT_APP_AIRTABLE_ARTICLES_TABLE_NAME}`, {
                headers: {
                    'Authorization': `Bearer ${process.env.REACT_APP_AIRTABLE_ARTICLES_API_KEY}`,
                    'Content-Type': 'application/json'
                }
            });
            // set the data in the state using the setData function
            setData(response.data);
        };
        fetchData();
    }, []);

    // render the component only if the data has been fetched
    if (!data) {
        return <div>Loading...</div>;
    }
    return (

        <ArticleLayout articleList={data.records} />

    )
};

