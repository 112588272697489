import { Padding } from '../compLib';
import { FunkyForm } from '../myforms';
import { NavbarV2 } from '../navbar/NavBar';
import './main.css'


const ContactUs = () => {
    return (
        <>
            <NavbarV2
                logoRef='/'
                brand="Benevosa"
                navItems={[
                    { label: 'Home', href: '/' },
                    { label: 'About', href: '/about' },
                    // { label: 'Articles', href: '/articles' },
                    // { label: 'Team', href: '/team' },
                    { label: '#Challenge', href: '/environment-challenge' },
                    { label: 'Contact', href: '/contact' },
                    { label: 'Newsletter', href: '/newsletter' },
                ]} />
                <Padding padding='2rem' />
            <h1>
                Contact Us
            </h1>

            <FunkyForm
                h1="We're happy to hear from you"
                h2="Fill out the form below and let's take it from there"
                h3="Select one below"
                fundingOptionText="Funding"
                volunteeringOptionText="Volunteering"
                ideaBoxOptionText="Idea box"
                FeedbackOptionText="Feedback"
                OtherOptionText="Other"
                DetailsTextTitle="Details"
                FeedbackTextTitle="What's the feedback about?"
                EmailAddressText="Email Address"
                ButtonSumbitText="Submit"
                FormResetH2Text='Thank you for your submission!'
                // FormResetPText='We will get back to you as soon as possible.'
                FormResetSubmitAgainText='Submit another form'
                FormResetHomeButtonText='Back to Home'
            />
        </>

    );
};

export default ContactUs;
