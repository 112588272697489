import styled from 'styled-components';
import { ImageAndTextOntopSection, Padding } from '../compLib';
import { FunkyForm } from '../myforms';
import { NavbarV2 } from '../navbar/NavBar';
import climateImg from '../../assets/images/climate3.png'



const Climate = () => {
    return (
        <>
            <NavbarV2
                logoRef='/'
                brand="Benevosa"
                navItems={[
                    { label: 'Home', href: '/' },
                    { label: 'About', href: '/about' },
                    // { label: 'Articles', href: '/articles' },
                    // { label: 'Team', href: '/team' },
                    { label: '#Challenge', href: '/environment-challenge' },
                    { label: 'Contact', href: '/contact' },
                    { label: 'Newsletter', href: '/newsletter' },
                ]} />
                <Padding padding='2rem' />
            <ImageAndTextOntopSection
                header='Climate'
                imageUrl={climateImg}
                paragraph='We are a non-profit organization that helps fund and implement sustainable projects that benefit the world'
                buttonText='Take the climate challenge'
                buttonUrl='/climate-challenge'
                textColor='white'
            />
            <ArticleDiv>
                <p>
                    One of the most effective ways to help the environment is to reduce your carbon footprint, and there are many ways to do this. However, not all methods are equal in terms of cost effectiveness. Here are a few tips for maximizing the environmental impact of your efforts while minimizing the cost:
                </p>
                <ul>
                    <p>Drive less: This is one of the most effective ways to reduce your carbon footprint, but it's also one of the most expensive. If you can, try to carpool or use public transportation whenever possible.</p>
                    <p>Use energy-efficient appliances and light bulbs: These use less energy and can save you money on your energy bills in the long run.</p>
                    <p>Plant trees and other vegetation: Trees absorb carbon dioxide from the atmosphere and can help offset your carbon emissions. This is a relatively low-cost option that can have a big impact over time.</p>
                    <p>Support renewable energy: Consider purchasing renewable energy credits or investing in a renewable energy project. While the upfront cost may be higher, the long-term benefits for the environment are significant.</p>
                    <p>Reduce, reuse, and recycle: Reducing your waste can help reduce the strain on landfills and save resources. Reusing items and recycling whenever possible can also help.</p>
                    <p>Support conservation efforts: Donating to organizations that work to protect natural habitats and biodiversity can have a big impact on the environment.</p>
                </ul>
                <p>
                    By implementing some or all of these strategies, you can make a difference for the environment while also saving money in the long run. Every little bit helps, so don't be afraid to start small and gradually make more sustainable choices as you go.
                </p>
            </ArticleDiv>
            <FunkyForm
                h1="We're happy to hear from you"
                h2="Fill out the form below and let's take it from there"
                h3="Select one below"
                fundingOptionText="Funding"
                volunteeringOptionText="Volunteering"
                ideaBoxOptionText="Idea box"
                FeedbackOptionText="Feedback"
                OtherOptionText="Other"
                DetailsTextTitle="Details"
                FeedbackTextTitle="What's the feedback about?"
                EmailAddressText="Email Address"
                ButtonSumbitText="Submit"
                FormResetH2Text='Thank you for your submission!'
                // FormResetPText='We will get back to you as soon as possible.'
                FormResetSubmitAgainText='Submit another form'
                FormResetHomeButtonText='Back to Home'
            />
        </>

    );
};

export default Climate;

const ArticleDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    max-width: 800px;
    padding: 0 1rem;
    margin-bottom: 2rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    background-color: #fff;
    color: #000;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.2rem;
    line-height: 1.5;
    text-align: center;
    @media (min-width: 768px) {
        font-size: 1.4rem;
    }
    @media (min-width: 1024px) {
        font-size: 1.6rem;
    }
    @media (min-width: 1280px) {
        font-size: 1.8rem;
    }
    @media (min-width: 1440px) {
        font-size: 2rem;
    
    }
    @media (min-width: 1600px) {
        font-size: 2.2rem;
    }
    @media (min-width: 1920px) {
        font-size: 2.4rem;
    }
    `