import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import "./cookie.css"


const CookiePopup: React.FC = () => {
    const [active, setActive] = useState(false);
    const [cookies, setCookie] = useCookies(['BenevosaAllowCookies']);
    useEffect(() => {
        const cookieValue = cookies['BenevosaAllowCookies'];
        if (!cookieValue) {
            setActive(true);
        }
    }, []);

    const handleAccept = () => {
        setCookie("BenevosaAllowCookies", true, { expires: new Date(Date.now() + 3600000 * 24 * 365) })
        setActive(false);
    };

    const handleReject = () => {
        setActive(false);
    };

    return (
        <div className={`cookie-popup ${active ? 'active' : ''}`}>
            ({window.location.href.includes("/no")}) &&
            {<p>Denne nettsiden bruker viktige informasjonskapsler for å forbedre opplevelsen din</p>}
            (!{window.location.href.includes("/no")}) &&
            {<p>This website uses essential cookies to improve your experience</p>}

            <button className="accept" onClick={handleAccept}>Accept</button>
            <button className="reject" onClick={handleReject}>Reject</button>
        </div>
    );
};

export default CookiePopup;