import { useEffect, useState } from 'react';
import "./navbar.css"

interface NavItem {
    label: string;
    href: string;
}

interface NavbarProps {
    brand: string;
    navItems: NavItem[];
    logoRef: string;
}

export const NavbarV2: React.FC<NavbarProps> = (props) => {
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth < 768);
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleDropdown = () => setIsDropdownVisible(!isDropdownVisible);
    const closeDropdown = () => setIsDropdownVisible(false);

    return (
        <nav className="navbar">
            <a className="navbar-brand" href={props.logoRef}>{props.brand}</a>
            {isMobile ? (
                <button
                    className="navbar-toggler"
                    type="button"
                    onClick={toggleDropdown}
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
            ) : null}
            {isMobile ? (
                <div
                    className={`navbar-collapse${isDropdownVisible ? ' show' : ''}`}
                >
                    <button
                        className="navbar-toggler-close"
                        type="button"
                        onClick={closeDropdown}
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <ul className="navbar-nav">
                        {props.navItems.map((item, index) => (
                            <li className="nav-item" key={index}>
                                <a className="nav-link" href={item.href}>{item.label}</a>
                            </li>
                        ))}
                    </ul>
                </div>
            ) : (
                <ul className="navbar-nav">
                    {props.navItems.map((item, index) => (
                        <li className="nav-item" key={index}>
                            <a className="nav-link" href={item.href}>{item.label}</a>
                        </li>
                    ))}
                </ul>
            )}
        </nav>
    );
};

