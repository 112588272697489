import { DividerSection, LopsidedDoubleDividerSection, Padding } from '../compLib';
import { NavbarV2 } from '../navbar/NavBar';
import { FunkyForm } from '../myforms';

import rustyBikeImg from '../../assets/images/rusty_bike.png';
import microscopeImg from '../../assets/images/microscope.png';
import './main.css'

const About = () => {
    return (
        <>
            <NavbarV2
                logoRef='/'
                brand="Benevosa"
                navItems={[
                    { label: 'Home', href: '/' },
                    { label: 'About', href: '/about' },
                    // { label: 'Articles', href: '/articles' },
                    // { label: 'Team', href: '/team' },
                    { label: '#Challenge', href: '/environment-challenge' },
                    { label: 'Contact', href: '/contact' },
                    { label: 'Newsletter', href: '/newsletter' },
                ]} />
                <Padding padding='2rem' />
            <h1>
                About
            </h1>

            <DividerSection firstText="WHAT WE BELIEVE IN" secondText="Making progress playful" />
            <LopsidedDoubleDividerSection bigHeader=''
                inverse={false}
                smallHeader="Technology"
                paragraph="We believe in the power of technology to make the world a better place"
                imageUrl={microscopeImg}
            />
            <LopsidedDoubleDividerSection bigHeader=''
                inverse={true}
                smallHeader="Repair and recycling"
                paragraph="We believe in repairing and recycling should be the default, not buying something new"
                imageUrl={rustyBikeImg}
            />
            {/* <h1>
                Other pursuits
            </h1>
            <ArticleFlexgrid /> */}
            {/* 
            <SocialFooter
                // instagramUrl='https://instagram.com/torbjornop'
                // instagramUrlText='Personal'
                discordUrl='https://discord.gg/FbFy9YSj'
                discordUrlText='TOP Creators'
            // youtubeUrl1='https://www.youtube.com/channel/UC7HTwWCjytIwPe1Oxec8zkg'
            // youtubeUrl1Text='Personal'
            // youtubeUrl2='https://www.youtube.com/channel/UCutatAiaiBxWoxKCSYpdHUQ'
            // youtubeUrl2Text='Tech By Thorbear'
            // twitchUrl='https://www.twitch.tv/thedjomega'
            // twitchUrlText='Kappa'
            // musicUrl="https://soundcloud.com/tropical-toby"
            // musicUrlText="TropicalToby" 
            /> */}
            <FunkyForm
                h1="We're happy to hear from you"
                h2="Fill out the form below and let's take it from there"
                h3="Select one below"
                fundingOptionText="Funding"
                volunteeringOptionText="Volunteering"
                ideaBoxOptionText="Idea box"
                FeedbackOptionText="Feedback"
                OtherOptionText="Other"
                DetailsTextTitle="Details"
                FeedbackTextTitle="What's the feedback about?"
                EmailAddressText="Email Address"
                ButtonSumbitText="Submit"
                FormResetH2Text='Thank you for your submission!'
                // FormResetPText='We will get back to you as soon as possible.'
                FormResetSubmitAgainText='Submit another form'
                FormResetHomeButtonText='Back to Home'
            />

        </>
    );
};

export default About;

