import styled from 'styled-components';
import { ImageAndTextOntopSection, Padding } from '../compLib';
import { FunkyForm } from '../myforms';
import { NavbarV2 } from '../navbar/NavBar';
import educationImg from '../../assets/images/learn.png'



const Education = () => {
    return (
        <>
            <NavbarV2
                logoRef='/'
                brand="Benevosa"
                navItems={[
                    { label: 'Home', href: '/' },
                    { label: 'About', href: '/about' },
                    // { label: 'Articles', href: '/articles' },
                    // { label: 'Team', href: '/team' },
                    { label: '#Challenge', href: '/environment-challenge' },
                    { label: 'Contact', href: '/contact' },
                    { label: 'Newsletter', href: '/newsletter' },
                ]} />
                <Padding padding='2rem' />

            <ImageAndTextOntopSection
                header='Education'
                imageUrl={educationImg}
                paragraph='Tools, methods, curriculums, mentorship and more.'
                buttonText='Register to be notified of volunteering opportunities'
                buttonUrl='/contact'
                textColor='white'
            />
            <ArticleDiv>
                <p>
                    Education is the key to a better future. We believe that everyone should have access to the tools and resources they need to succeed. We also believe that education should be accessible to everyone, regardless of their background or financial situation.
                    We support a variety of education initiatives, including:
                </p>
                <ul>
                    <p>Improving the quality, but also the diversity of education</p>
                    <p>Making it accessible to as many as possible, without sacrificing quality</p>
                    <p>Providing the tools and resources needed to succeed</p>
                    <p>Providing mentorship and guidance</p>
                </ul>
                <p>
                    We're currently working on a few projects that we hope will help improve education for all. If you're interested in helping us out, please contact us.
                </p>
            </ArticleDiv>
            <FunkyForm
                h1="We're happy to hear from you"
                h2="Fill out the form below and let's take it from there"
                h3="Select one below"
                fundingOptionText="Funding"
                volunteeringOptionText="Volunteering"
                ideaBoxOptionText="Idea box"
                FeedbackOptionText="Feedback"
                OtherOptionText="Other"
                DetailsTextTitle="Details"
                FeedbackTextTitle="What's the feedback about?"
                EmailAddressText="Email Address"
                ButtonSumbitText="Submit"
                FormResetH2Text='Thank you for your submission!'
                // FormResetPText='We will get back to you as soon as possible.'
                FormResetSubmitAgainText='Submit another form'
                FormResetHomeButtonText='Back to Home'
            />
        </>

    );
};

export default Education;

const ArticleDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    max-width: 800px;
    padding: 0 1rem;
    margin-bottom: 2rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    background-color: #fff;
    color: #000;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.2rem;
    line-height: 1.5;
    text-align: center;
    @media (min-width: 768px) {
        font-size: 1.4rem;
    }
    @media (min-width: 1024px) {
        font-size: 1.6rem;
    }
    @media (min-width: 1280px) {
        font-size: 1.8rem;
    }
    @media (min-width: 1440px) {
        font-size: 2rem;
    
    }
    @media (min-width: 1600px) {
        font-size: 2.2rem;
    }
    @media (min-width: 1920px) {
        font-size: 2.4rem;
    }
    `
