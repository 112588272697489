import React from 'react';
import { Padding } from '../compLib';

import { MailForm } from '../myforms';

import { NavbarV2 } from '../navbar/NavBar';
import './main.css'


const Newsletter = () => {
    return (
        <>
            <NavbarV2
                logoRef='/'
                brand="Benevosa"
                navItems={[
                    { label: 'Home', href: '/' },
                    { label: 'About', href: '/about' },
                    // { label: 'Articles', href: '/articles' },
                    // { label: 'Team', href: '/team' },
                    { label: '#Challenge', href: '/environment-challenge' },
                    { label: 'Contact', href: '/contact' },
                    { label: 'Newsletter', href: '/newsletter' },
                ]} />
            <Padding padding='2rem' />
            <h1>
                Newsletter
            </h1>
            <MailForm
                MailFormTitle='Write down your details to subscribe to the newsletter'
                AccordionFirstText='Privacy policy'
                AccordionSecondText='Click to read more'
                AccordionDropDownDetailsText='By submitting this form, you agree to have your email stored and used for the purposes specified in our '
                AccordionDropDownDetailsLinkText='privacy policy'
                AccordionDropDownDetailsTextAfterLink='. You can withdraw your consent at any time by contacting us or using the unsubscribe link in our emails.'
                ModalTitle='PRIVACY POLICY'
                ModalText='At **Benevosa**, we are committed to protecting the privacy of our users. This Privacy Policy explains how we collect, use, and share information about you when you use our website [website] and our services [services].<br><br>
                By using our website and services, you agree to the collection, use, and sharing of your information as described in this Privacy Policy. If you do not agree with our policies and practices, do not use our website or services.<br><br>
                COLLECTION OF INFORMATION<br><br>
                We collect information about you in the following ways:<br><br>
                Information you provide to us: We collect information that you provide to us when you create an account, fill out a form, or communicate with us. This may include your name, email address, phone number, and any other personal information you choose to provide.<br><br>
                Information we collect automatically: When you use our website or services, we may collect certain information automatically, such as your IP address, browser type, device type, and browsing history. We may also collect information about your location and the website you visited before coming to our website.<br><br>
                USE OF INFORMATION<br><br>
                We use the information we collect about you to:<br><br>
                Provide and improve our website and services: We use the information to operate, maintain, and improve our website and services.<br><br>
                Communicate with you: We may use the information to communicate with you about your account, to respond to your requests or inquiries, or to send you newsletters or other communications.<br><br>
                Protect our rights and interests: We may use the information to protect the security and integrity of our website and services, and to defend against legal claims.<br><br>
                SHARING OF INFORMATION<br><br>
                We do not sell, rent, or share your personal information with third parties for their marketing purposes without your consent. We may share your information in the following ways:<br><br>
                With service providers: We may share your information with third parties who provide services on our behalf, such as hosting, maintenance, and analytics.<br><br>
                As required by law: We may disclose your information if we are required to do so by law or if we believe in good faith that such disclosure is necessary to comply with legal process, protect the rights or property of [Company Name], or to protect the safety of the public.<br><br>
                SECURITY OF INFORMATION<br><br>
                We take reasonable measures to protect the information we collect from loss, misuse, and unauthorized access or disclosure. However, no internet or email transmission is ever fully secure or error-free. Therefore, you should take special care in deciding what information you send to us in this way.<br><br>
                CHANGES TO THIS PRIVACY POLICY<br><br>
                We may update our Privacy Policy from time to time. We will post any changes on this page and encourage you to review our Privacy Policy whenever you access our website or services.<br><br>
                CONTACT US<br><br>
                If you have any questions about this Privacy Policy, please contact us via the contact form on this website.<br><br>
                This privacy policy is compliant with the General Data Protection Regulation (GDPR) and the Payment Card Industry Data Security Standard (PCI DSS).
            '
                ConsentText='I have read and I understand the consent message and agree to the specified terms'
                FirstNameText='First Name'
                LastNameText='Last Name'
                EmailText='Email Address'
                SubmitButtonText='Subscribe'
                FormResetH2Text='Thank you for subscribing to our newsletter'
                // FormResetText='We will send you an email when we publish a new article.'
                FormResetSubmitAgainText=''
                FormResetHomeButtonText='Back to Home'
            />
            {/*             
            <BlogSection
                articles={[
                    {
                        imageUrl: 'article-1.jpg',
                        title: 'Article 1',
                        date: 'January 1, 2020',
                        excerpt: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eget bibendum ante. In hac habitasse platea dictumst. Suspendisse potenti.',
                    },
                    {
                        imageUrl: 'article-2.jpg',
                        title: 'Article 2',
                        date: 'February 1, 2020',
                        excerpt: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eget bibendum ante. In hac habitasse platea dictumst. Suspendisse potenti.',
                    },
                    {
                        imageUrl: 'article-3.jpg',
                        title: 'Article 3',
                        date: 'March 1, 2020',
                        excerpt: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eget bibendum ante. In hac habitasse platea dictumst. Suspendisse potenti.',
                    },
                ]}
            /> */}
        </>
    );
};

export default Newsletter;

