import axios from 'axios';
import { useState } from 'react';
import Rating from '@mui/material/Rating';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useCookies } from 'react-cookie';
import { FlexibleDiv200h, MyButton } from './compLib';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Markdown from 'markdown-to-jsx';

// define the type of the setState function as a function that takes a State object and returns void
type SetState = (hasSubmitted: boolean) => void;

type SetFormState = (formState: {
    dropdown: string;
    formRequestDetails: string;
    email: string;
}) => void;

type SetForm2State = (formState: {
    interests: string[];
    firstName: string;
    lastName: string;
    email: string;
    consentBox: boolean;
}) => void;

// define the type of the props for the Child component as an object with a SetState function prop 'setState'
type FormResetProps = {
    setHasSubmitted: SetState;
    setFormState: SetFormState;
    FormResetH2Text: string;
    FormResetSubmitAgainText: string;
    FormResetHomeButtonText: string;
};

const FormReset = ({ setHasSubmitted, setFormState }: FormResetProps) => {

    const handleReset = () => {
        setHasSubmitted(false)
        setFormState({
            dropdown: 'idea',
            formRequestDetails: '',
            email: ''
        })
    }

    return (
        <FlexibleDiv200h>
            <h2>Awesome!</h2>
            <Rating name="read-only" value={5} readOnly />
            <MyButton onClick={handleReset} type="submit">Send another request</MyButton>
            <a href='/'>
                <MyButton>Home</MyButton>
            </a>
        </FlexibleDiv200h>
    )
}


type Form2ResetProps = {
    setHasSubmitted: SetState;
    setFormState: SetForm2State;
    FormResetH2Text: string;
    FormResetSubmitAgainText: string;
    FormResetHomeButtonText: string;
};


const FormReset2 = ({ setHasSubmitted, setFormState }: Form2ResetProps) => {
    const handleReset = () => {
        setHasSubmitted(false)
        setFormState({
            interests: [""],
            firstName: "",
            lastName: "",
            email: "",
            consentBox: false,
        },
        )
    }

    return (
        <FlexibleDiv200h>
            <h2>Awesome!</h2>
            <Rating name="read-only" value={5} readOnly />
            <MyButton onClick={handleReset} type="submit">Send another request</MyButton>
            <a href='/'>
                <MyButton>Home</MyButton>
            </a>
        </FlexibleDiv200h>
    )
}

interface FunkyFormProps {
    h1: string;//Let's work together
    h2: string; //Fill out the form below and let's take it from there
    h3: string; //How can we help you?
    fundingOptionText: string;
    volunteeringOptionText: string;
    ideaBoxOptionText: string;
    FeedbackOptionText: string;
    OtherOptionText: string;
    DetailsTextTitle: string;
    FeedbackTextTitle: string;
    EmailAddressText: string;
    ButtonSumbitText: string;

    FormResetH2Text: string;
    FormResetSubmitAgainText: string;
    FormResetHomeButtonText: string;
}

export const FunkyForm = (props: FunkyFormProps) => {
    // define the form state using the useState hook
    const [hasSubmitted, setHasSubmitted] = useState(false)
    const [formState, setFormState] = useState({
        dropdown: 'funding',
        formRequestDetails: '',
        email: ''
    });

    // handle form input changes
    const handleChange = (event: { target: { name: any; value: any; }; }) => {
        const { name, value } = event.target;
        setFormState((prevState) => ({ ...prevState, [name]: value }));
    };

    // handle form submission
    const handleSubmit = (event: { preventDefault: () => void; }) => {
        event.preventDefault();

        // send the form data to Airtable using the Airtable API
        axios.post(`https://api.airtable.com/v0/${process.env.REACT_APP_AIRTABLE_FORM_SUBMISSION_BASE_ID}/${process.env.REACT_APP_AIRTABLE_FORM_SUBMISSION_TABLE_NAME}`, {
            fields: {
                'Name': "Inbound request",
                'FilledBy': "API",
                'RequestType': formState.dropdown,
                'Description': formState.formRequestDetails,
                'Email': String(formState.email)
            }
        }, {
            headers: {
                'Authorization': `Bearer ${process.env.REACT_APP_AIRTABLE_FORM_SUBMISSION_API_KEY}`,
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                // handle the response from Airtable
                // console.log(response);
            })
            .catch((error) => {
                // handle any errors
                // console.error(error);
            });
        setHasSubmitted(true)
    };
    return (
        <>
            {(!hasSubmitted) ?
                <form className="form" onSubmit={handleSubmit}>
                    <h1>{props.h1}</h1>
                    <h2>{props.h2}</h2>
                    <label htmlFor="service">{props.h3}</label>
                    <select id="service" name="dropdown"
                        value={formState.dropdown} onChange={handleChange}>
                        {/* <option value="technology">Technology</option> */}
                        <option value="funding">{props.fundingOptionText}</option>
                        <option value="volunteering">{props.volunteeringOptionText}</option>
                        <option value="marketing">{props.ideaBoxOptionText}</option>
                        <option value="feedback">{props.FeedbackOptionText}</option>
                        <option value="other">{props.OtherOptionText}</option>
                    </select>
                    {(formState.dropdown !== "feedback") &&
                        < label htmlFor="form-request-details">{props.DetailsTextTitle}</label>
                    }
                    {(formState.dropdown === "feedback") &&
                        < label htmlFor="form-request-details">{props.FeedbackTextTitle}</label>
                    }
                    <textarea
                        id="form-request-details" name="formRequestDetails"
                        value={formState.formRequestDetails}
                        onChange={handleChange} />
                    <label htmlFor="email">{props.EmailAddressText}</label>
                    <input type="email" id="email" name="email" required value={formState.email}
                        onChange={handleChange} />
                    <button type="submit">{props.ButtonSumbitText}</button>
                </form>
                :
                <FormReset setHasSubmitted={setHasSubmitted} setFormState={setFormState} FormResetH2Text={props.FormResetH2Text} FormResetHomeButtonText={props.FormResetHomeButtonText} FormResetSubmitAgainText={props.FormResetSubmitAgainText} />
            }
        </>
    );
};

interface MailFormProps {
    MailFormTitle: string;
    AccordionDropDownDetailsText: string;
    AccordionDropDownDetailsLinkText: string;
    AccordionDropDownDetailsTextAfterLink: string;
    AccordionFirstText: string;
    AccordionSecondText: string;
    ModalTitle: string;
    ModalText: string;
    EmailText: string;
    FirstNameText: string;
    LastNameText: string;
    ConsentText: string;
    SubmitButtonText: string;

    FormResetH2Text: string;
    FormResetHomeButtonText: string;
    FormResetSubmitAgainText: string;
}


export const MailForm = (props: MailFormProps) => {
    const [modalState, setModalState] = useState(false);
    const handleOpen = () => setModalState(true);
    const handleClose = () => setModalState(false);
    const [cookies, setCookie] = useCookies(['TopheimEmailConsent']);
    const consentBoxFromCookies = cookies['TopheimEmailConsent'];
    // define the form state using the useState hook
    const [hasSubmitted, setHasSubmitted] = useState(false)
    const [formState, setFormState] = useState({
        interests: ["shop", "newsletter"],
        email: '',
        firstName: "",
        lastName: '',
        consentBox: consentBoxFromCookies ? true : false,
    });

    // handle form input changes
    const handleChange = (event: { target: { name: any; value: any; }; }) => {
        const { name, value } = event.target;
        setFormState((prevState) => ({ ...prevState, [name]: value }));
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // handle form submission
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        // Check the value of the checkbox
        // @ts-ignore: html index issues
        const checkbox = event.currentTarget.elements['consent-checkbox'].checked
        if (checkbox) {
            // If the checkbox is checked, store the email in a cookie
            const emailInput = formState.email;
            setCookie('TopheimEmailConsent', emailInput);
        } else {
            // If the checkbox is not checked, do not store the email
        }
        // send the form data to Airtable using the Airtable API
        axios.post(`https://api.airtable.com/v0/${process.env.REACT_APP_AIRTABLE_FORM_SUBMISSION_BASE_ID}/${process.env.REACT_APP_AIRTABLE_FORM_SUBMISSION_TABLE_NAME}`, {
            fields: {
                'Name': "Inbound request",
                'FilledBy': "API",
                'RequestType': "MailingList - Shop",
                'Email': String(formState.email),
                'MailingListInterests': formState.interests,
                'CustomerFirstName': String(formState.firstName),
                'CustomerLastName': String(formState.lastName),
            }
        }, {
            headers: {
                'Authorization': `Bearer ${process.env.REACT_APP_AIRTABLE_FORM_SUBMISSION_API_KEY}`,
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                // handle the response from Airtable
                // console.log(response);
            })
            .catch((error) => {
                // handle any errors
                // console.error(error);
            });
        setHasSubmitted(true)
    };

    const [expanded, setExpanded] = useState<string | false>(false);

    const handleAccordianChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    const ModalStyle = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        maxHeight: '80%',
        maxWidth: "90%",
        transform: 'translate(-50%, -50%)',
        overflow: "scroll",
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };


    return (
        <>
            {(!hasSubmitted) ?
                <form className="form" onSubmit={handleSubmit}>
                    <label htmlFor="service">{props.MailFormTitle}</label>
                    <div style={{ maxWidth: "600px", display: "flex", margin: "auto" }}>
                        <Accordion expanded={expanded === 'panel1'} onChange={handleAccordianChange('panel1')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                    {props.AccordionFirstText}
                                </Typography>
                                <Typography sx={{ color: 'text.secondary' }}>{props.AccordionSecondText}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    {props.AccordionDropDownDetailsText} <a href="#/" style={{ cursor: "pointer", color: "blue" }} onClick={handleOpen}>{props.AccordionDropDownDetailsLinkText}</a>{props.AccordionDropDownDetailsTextAfterLink}

                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Modal
                            aria-labelledby="transition-modal-title"
                            aria-describedby="transition-modal-description"
                            open={modalState}
                            onClose={handleClose}
                            closeAfterTransition
                        >
                            <Fade in={modalState}>
                                <Box sx={ModalStyle}>
                                    <Typography id="transition-modal-title" variant="h6" component="h2">
                                        {props.ModalTitle}
                                    </Typography>
                                    <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                                        <Markdown>{props.ModalText}</Markdown>
                                    </Typography>
                                </Box>
                            </Fade>
                        </Modal>
                    </div>
                    <FlexibleDiv200h style={{ height: "100px" }}>
                        <label>
                            {props.ConsentText}
                            <input type="checkbox" name="consent-checkbox" />
                        </label>
                    </FlexibleDiv200h>
                    {/* <Select  options={interestOptions}
                        value={state}
                        isMulti

                    /> */}

                    <label htmlFor="firstName">{props.FirstNameText}</label>
                    <input id="firstName" name="firstName"
                        value={formState.firstName}
                        onChange={handleChange} />

                    <label htmlFor="lastName">{props.LastNameText}</label>
                    <input id="lastName" name="lastName"
                        value={formState.lastName}
                        onChange={handleChange} />

                    <label htmlFor="email">{props.EmailText}</label>
                    <input type="email" id="email" name="email" required value={formState.email}
                        onChange={handleChange} />
                    <button type="submit">{props.SubmitButtonText}</button>
                </form>
                :
                <FormReset2 setHasSubmitted={setHasSubmitted} setFormState={setFormState} FormResetH2Text={props.FormResetH2Text} FormResetHomeButtonText={props.FormResetHomeButtonText} FormResetSubmitAgainText={props.FormResetSubmitAgainText} />
            }
        </>
    );
};

