import './App.css';
import {
  BrowserRouter,
  Routes, // instead of "Switch"
  Route,
} from "react-router-dom";
import LandingPage from './components/main/LandingPage';
import Newsletter from './components/main/Newsletter';
import Contact from './components/main/Contact'
import Articles from './components/main/Articles';
import About from './components/main/About';
import CookiePopup from './components/cookies/CookiePopup';
import { useCookies } from 'react-cookie';
import Climate from './components/main/Climate';
import Education from './components/main/Education';
import Challenge from './components/main/Challenge';

function App() {
  const [cookies] = useCookies(['BenevosaAllowCookies']);
  const allowCookies = cookies["BenevosaAllowCookies"]

  return (
    <div className="App">
      {allowCookies && (
        <CookiePopup />
      )}
      <Router />
    </div>
  );
}

const Router = () => {
  // define the state variable and setState function using the useState hook

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/articles" element={<Articles />} />
        <Route path="/about" element={<About />} />
        <Route path="/newsletter" element={<Newsletter />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/education" element={<Education />} />
        <Route path="/climate" element={<Climate />} />
        <Route path="/environment-challenge" element={<Challenge />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
