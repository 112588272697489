import { NavbarV2 } from '../navbar/NavBar';
// import photographerImg from '../../assets/images/photographer.jpg';
import renewableSolarImg from '../../assets/images/renewableSolar.png';
import educationImg from '../../assets/images/learn.png';
import { ChevronDown } from 'react-feather';
// import directCameraImg from '../../assets/images/direct_camera.jpg';
// import liveDjImg from '../../assets/images/live_dj.png';

import { FunkyForm } from '../myforms';
import { FontSizeOptions, HeroText, LopsidedDoubleDividerSection, Padding } from '../compLib';
import './main.css'



const LandingPage = () => {

  return (
    <>
      <NavbarV2
        logoRef='/'
        brand="Benevosa"
        navItems={[
          { label: 'Home', href: '/' },
          { label: 'About', href: '/about' },
          // { label: 'Articles', href: '/articles' },
          // { label: 'Team', href: '/team' },
          { label: '#Challenge', href: '/environment-challenge' },
          { label: 'Contact', href: '/contact' },
          { label: 'Newsletter', href: '/newsletter' },
        ]} />
      <Padding padding='2rem' />
      <HeroText
        fontSize={FontSizeOptions.large}
        textColor='black'
        header="Benevosa"
        paragraph="We're a non-profit organization that helps fund and implement sustainable projects that benefit the world"
        buttonText="Contact us"
        buttonUrl='/contact'
      />
      <ChevronDown />

      <LopsidedDoubleDividerSection
        inverse={false}
        imageUrl={renewableSolarImg}
        smallHeader="Climate change"
        bigHeader="Renewable energy and sustainable living"
        paragraph="
          Join us in funding research that further renewable energy technology or implementing green systems where they're needed, but don't have the funds
        "
        buttonText="Learn More"
        buttonUrl='/climate'
      />

      <LopsidedDoubleDividerSection
        inverse={true}
        imageUrl={educationImg}
        smallHeader="Education"
        bigHeader="Proper education for all"
        paragraph="Tools, methods, curriculums, mentorship and more."
        buttonText="Learn More"
        buttonUrl='/education'
      />

      <Padding padding="1rem" />
      <FunkyForm
        h1="We're happy to hear from you"
        h2="Fill out the form below and let's take it from there"
        h3="Select one below"
        fundingOptionText="Funding"
        volunteeringOptionText="Volunteering"
        ideaBoxOptionText="Idea box"
        FeedbackOptionText="Feedback"
        OtherOptionText="Other"
        DetailsTextTitle="Details"
        FeedbackTextTitle="What's the feedback about?"
        EmailAddressText="Email Address"
        ButtonSumbitText="Submit"
        FormResetH2Text='Thank you for your submission!'
        // FormResetPText='We will get back to you as soon as possible.'
        FormResetSubmitAgainText='Submit another form'
        FormResetHomeButtonText='Back to Home'
      />

    </>
  );
};

export default LandingPage;
