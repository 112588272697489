import { useEffect, useState } from 'react';
import { SocialIcon } from 'react-social-icons';
import styled from 'styled-components';

export const MyButton = styled.button`
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
background: black;
border: none;
padding: 0.5em 1em;
color: white;
border-radius: 5px;
margin: auto;
margin-bottom: 1rem;
margin-top: 1rem;
&:hover {
    cursor:pointer
}
`
export const FlexibleDiv200h = styled.div`
display: flex;
height: 200px;
flex-direction: column;
align-items: center;
justify-content: space-evenly;
`


export const ArticleDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 90%;
    padding: 0 1rem;
    margin-bottom: 2rem;
    margin-top: 2rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    background-color: #fff;
    color: #000;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.2rem;
    line-height: 1.5;
    text-align: center;
    `
export enum FontSizeOptions {
    small = 'small',
    medium = 'medium',
    large = 'large'
}
interface FunkyHeaderProps {
    text: string;
    fontSize: FontSizeOptions

}
export const FunkyHeader = (props: FunkyHeaderProps) => {
    return (
        <>
            {
                (props.fontSize === FontSizeOptions.medium) ?
                    <div className="moveBox">
                        <div className="AnimatingTextMedium">{props.text}</div>
                    </div>
                    : (props.fontSize === FontSizeOptions.small) ?
                        <div className="moveBox">
                            <div className="AnimatingTextSmall">{props.text}</div>
                        </div>
                        : <div className="moveBox">
                            <div className="AnimatingText">{props.text}</div>
                        </div>
            }
        </>
    )
}



interface PaddingProps {
    padding?: string;
}

export const Padding = (props: PaddingProps) => {
    return (
        <div style={{ padding: props.padding }}>
            <div />
        </div>
    )
}

interface SocialFooterProps {
    facebookUrl?: string;
    facebookUrlText?: string;
    twitterUrl?: string;
    twitterUrlText?: string;
    instagramUrl?: string;
    instagramUrlText?: string;
    discordUrl?: string;
    discordUrlText?: string;
    twitchUrl?: string;
    twitchUrlText?: string;
    youtubeUrl1?: string;
    youtubeUrl1Text?: string;
    youtubeUrl2?: string;
    youtubeUrl2Text?: string;
    musicUrl?: string;
    musicUrlText?: string;
}

export const SocialFooter: React.FC<SocialFooterProps> = (props) => {
    return (
        <div>
            <h1>Socials</h1>
            <Padding padding='1rem' />
            <footer className="footer">
                <div className="container">
                    {(props.facebookUrl) ?
                        <div className="social-links">
                            <SocialIcon style={{ padding: "1rem" }} url={props.facebookUrl} />
                            {props.facebookUrlText ?
                                <span>{props.facebookUrlText}</span>
                                : null}
                        </div>
                        : null}
                    {(props.twitterUrl) ?
                        <div className="social-links">
                            <SocialIcon style={{ padding: "1rem" }} url={props.twitterUrl} />
                            {props.twitterUrlText ?
                                <span>{props.twitterUrlText}</span>
                                : null}
                        </div>
                        : null}
                    {(props.instagramUrl) ?
                        <div className="social-links">
                            <SocialIcon style={{ padding: "1rem" }} url={props.instagramUrl} />
                            {props.instagramUrlText ?
                                <span>{props.instagramUrlText}</span>
                                : null}
                        </div>
                        : null}
                    {(props.discordUrl) ?
                        <div className="social-links">
                            <SocialIcon style={{ padding: "1rem" }} url={props.discordUrl} />
                            {props.discordUrlText ?
                                <span>{props.discordUrlText}</span>
                                : null}
                        </div>
                        : null}
                    {(props.twitchUrl) ?
                        <div className="social-links">
                            <SocialIcon style={{ padding: "1rem" }} url={props.twitchUrl} />
                            {props.twitchUrlText ?
                                <span>{props.twitchUrlText}</span>
                                : null}
                        </div>
                        : null}
                    {(props.youtubeUrl1) ?
                        <div className="social-links">
                            <SocialIcon style={{ padding: "1rem" }} url={props.youtubeUrl1} />
                            {props.youtubeUrl1Text ?

                                <span>{props.youtubeUrl1Text}</span>
                                : null}
                        </div>
                        : null}
                    {(props.youtubeUrl2) ?

                        <div className="social-links">
                            <SocialIcon style={{ padding: "1rem" }} url={props.youtubeUrl2} />
                            {props.youtubeUrl2Text ?
                                <span>{props.youtubeUrl2Text}</span>
                                : null}
                        </div>
                        : null}
                    {(props.musicUrl) ?
                        <div className='social-links'>
                            <SocialIcon style={{ padding: "1rem" }} url={props.musicUrl} />
                            {props.musicUrlText ?
                                <span>{props.musicUrlText}</span>
                                : null}
                        </div>
                        : null
                    }
                </div>
            </footer>
        </div>
    );
};



interface ImageAndTextOntopSectionProps {
    imageUrl?: string;
    header: string;
    paragraph: string;
    textColor: string;
    buttonText?: string;
    buttonUrl?: string;
    fontSize?: FontSizeOptions
}

export const ImageAndTextOntopSection: React.FC<ImageAndTextOntopSectionProps> = (props) => {
    const negativeColor = props.textColor === "white" ? "black" : "white";
    console.log(props.imageUrl)
    return (

        (props.imageUrl !== "undefined") ?
            <section className="section2" style={{ backgroundImage: `url(${props.imageUrl})` }}>
                <div className="container2">
                    <h1 style={{ color: props.textColor }}>{props.header}</h1>
                    <p style={{ color: props.textColor }}>{props.paragraph}</p>
                    {props.buttonText !== undefined && props.buttonUrl !== undefined ?
                        <a href={props.buttonUrl}>
                            <button style={{ backgroundColor: props.textColor, color: negativeColor }} className="cool-button">{props.buttonText}</button>
                        </a> :
                        null
                    }
                </div>
            </section>
            :
            <section className="section2">
                <div className="container2">
                    <FunkyHeader text={props.header} fontSize={props.fontSize ? props.fontSize : FontSizeOptions.large} />
                    {/* <h2 style={{ color: props.textColor }}>{props.header}</h2> */}
                    <p style={{ color: props.textColor }}>{props.paragraph}</p>
                    {props.buttonText !== undefined && props.buttonUrl !== undefined ?
                        <a href={props.buttonUrl}>
                            <button style={{ backgroundColor: props.textColor, color: negativeColor }} className="cool-button">{props.buttonText}</button>
                        </a> :
                        null
                    }
                </div>
            </section>

    );
};






interface HeroTextProps {
    imageUrl?: string;
    header: string;
    paragraph: string;
    textColor: string;
    buttonText?: string;
    buttonUrl?: string;
    fontSize: FontSizeOptions;
}


export const HeroText: React.FC<HeroTextProps> = (props) => {
    const negativeColor = props.textColor === "white" ? "black" : "white";
    return (
        // Actual image is provided
        (props.imageUrl !== undefined) ?
            <section className="section2">
                <img className="blurred-bg-img" src={props.imageUrl} alt="Blurred backdrop" />
                <div className="container2">
                    <FunkyHeader text={props.header} fontSize={props.fontSize} />
                    <div>
                        <SubtitleThing fontSize={props.fontSize} paragraph={props.paragraph} textColor={props.textColor} />
                    </div>
                    {props.buttonText !== undefined && props.buttonUrl !== undefined ?
                        <a href={props.buttonUrl}>
                            <button style={{ backgroundColor: props.textColor, color: negativeColor }} className="cool-button">{props.buttonText}</button>
                        </a> :
                        null
                    }
                </div>
            </section>
            :
            // No image provided
            <section className="section2">
                <div className="container2">
                    <FunkyHeader text={props.header} fontSize={props.fontSize} />
                    {/* <h2 style={{ color: props.textColor }}>{props.header}</h2> */}
                    <div style={{ flex: "contents" }}>
                        <p style={{ color: props.textColor }}>{props.paragraph}</p>
                    </div>
                    {props.buttonText !== undefined && props.buttonUrl !== undefined ?
                        <a href={props.buttonUrl}>
                            <button style={{ backgroundColor: props.textColor, color: negativeColor }} className="cool-button">{props.buttonText}</button>
                        </a> :
                        null
                    }
                </div>
            </section>

    );
};
export const SubtitleThing = (props: any) => {

    return (
        (props.fontSize === FontSizeOptions.small) ?
            <p style={{ color: props.textColor }} className="SubtitleThingSmall">{props.paragraph}</p>
            : (props.fontSize === FontSizeOptions.medium) ?
                <p style={{ color: props.textColor }} className="SubtitleThingMedium">{props.paragraph}</p>
                :
                <p style={{ color: props.textColor }} className="SubtitleThing">{props.paragraph}</p>
    )
}


interface TextAndImageSectionProps {
    imageUrl: string;
    buttonUrl?: string;
    smallHeader: string;
    bigHeader: string;
    paragraph: string;
    buttonText?: string;
    inverse: boolean;
}

export const LopsidedDoubleDividerSection: React.FC<TextAndImageSectionProps> = (props) => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth < 992);
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return (
        <>
            {
                !props.inverse ?
                    <section className="section">
                        <div className="container">

                            <div className="left-container-w-text">
                                <h4 >{props.smallHeader}</h4>
                                <h2 style={{ overflowWrap: "break-word" }}>{props.bigHeader}</h2>
                                <p >{props.paragraph}</p>
                                {props.buttonUrl !== undefined && props.buttonText !== undefined ?
                                    <a href={props.buttonUrl}>
                                        <button className="cool-button">{props.buttonText}</button>
                                    </a>
                                    :
                                    null
                                }
                            </div>
                            <div className="right-container-w-img">
                                <img src={props.imageUrl} alt="" />
                            </div>
                        </div>
                    </section>
                    :
                    isMobile ?
                        <section className="section">
                            <div className="container">
                                <div className="right-container-w-text">
                                    <h4>{props.smallHeader}</h4>
                                    <h2>{props.bigHeader}</h2>
                                    <p>{props.paragraph}</p>
                                    {props.buttonUrl !== undefined ?
                                        <a href={props.buttonUrl}>
                                            <button className="cool-button">{props.buttonText}</button>
                                        </a>
                                        :
                                        null
                                    }
                                </div>
                                <div className="left-container-w-img">
                                    <img src={props.imageUrl} alt="" />
                                </div>
                            </div>
                        </section>
                        :
                        <section className="section">
                            <div className="container">

                                <div className="left-container-w-img">
                                    <img src={props.imageUrl} alt="" />
                                </div>
                                <div className="right-container-w-text">
                                    <h4>{props.smallHeader}</h4>
                                    <h2>{props.bigHeader}</h2>
                                    <p>{props.paragraph}</p>
                                    {props.buttonUrl !== undefined ?
                                        <a href={props.buttonUrl}>
                                            <button className="cool-button">{props.buttonText}</button>
                                        </a>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                        </section>
            }

        </>
    );
}


export type AirtableArticleFields = {
    Article: string;
    ArticleTitle: string;
    Categories: string[];
    ImageAlt: string;
    ImageReference: string;
    Link: string;
    ShortText: string;
};

export type AirtableArticleItem = {
    fields: AirtableArticleFields
}

export interface ArticleLayoutProps {
    articleList: AirtableArticleItem[]
}

export const ArticleLayout: React.FC<ArticleLayoutProps> = (props) => {


    return (
        <section className="blog-section">
            <div className="container3">
                {props.articleList.map((article: { fields: AirtableArticleFields }, i: number) => (
                    <div className="article" key={i}>
                        <a href={article.fields.Link} className="read-more">
                            <div style={{ padding: "1rem" }}>
                                <h2>{article.fields.ArticleTitle}</h2>
                            </div>
                            <img src={article.fields.ImageReference} alt={article.fields.ImageAlt} />
                            <div style={{ padding: "1rem" }}>
                                <p className="excerpt">{article.fields.ShortText}</p>
                            </div>
                            <MyButton >
                                Read More
                            </MyButton>
                        </a>
                    </div>
                ))}
            </div>
        </section>
    );
};


export const SingleArticleLayout: React.FC<AirtableArticleFields> = (item) => {

    return (
        <section className="blog-section">
            <div className="container3">
                <div className="article">
                    <h1>{item.ArticleTitle}</h1>
                    <img src={item.ImageReference} alt={item.ImageAlt} />
                    <p>
                        {item.Article}
                    </p>
                </div>

            </div>
        </section>
    );
};

// interface for DividerSection
interface DividerSectionProps {
    firstText?: string,
    secondText?: string
}

export const DividerSection = (props: DividerSectionProps) => {
    return (
        <DividerSectionStyle >
            <Padding padding='1rem' />
            <SmallContrastText>
                {props.firstText}
            </SmallContrastText>
            <h2>
                {props.secondText}
            </h2>
            <Padding padding='1rem' />
        </DividerSectionStyle >
    )
}
// divider section styled div
export const DividerSectionStyle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    `
export const SmallContrastText = styled.h2`
    font-size: clamp(0.8rem, 10vw, 1.5em);
    font-family: coolvetica, helvetica, roboto, courier;
    font-weight: 300;
    color: gray;
    `

